import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="Sprive Surveys Privacy Policy" />
    <div className="common-page privacy-page">
      <HeadingSection h1="Surveys Privacy Policy" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <p>
        Pureprofile is the technology partner that enables the use of this account.
        Pureprofile is committed to protecting personal data. This privacy policy
        will inform how we look after business partner (<strong>“Partner”</strong>) personal data.
        This policy supplements our Pureprofile privacy policy and is not intended
        to override it. Pureprofile’s privacy policy, including the methods with
        which data is collected and used, is explained below.
      </p>
      <p>
        For the purposes of this policy the Partner will be referred to as 'The
        Community'.
      </p>
      <br/>
      <h4>Info for personal accounts</h4>
      <p>
        The Community is founded on the principle of making your everyday online
        activities more valuable. We know that you care about how your personal
        information is used and shared, and we take your privacy seriously.
      </p>
      <p>
        By visiting and using The Community you are accepting the practices outlined
        in this policy.
      </p>
      <br/>
      <h4>Introduction</h4>
      <p>
        By choosing to complete surveys through The Community you’ll be asked to
        provide some personal and sensitive information, as detailed here. Our
        Privacy Policy is based on the following provisions:
      </p>
      <ul>
        <li>
          We only store profile information you choose to provide. We use the latest
          technologies and best practises to protect all personal information from
          unauthorised physical and electronic access and interference
        </li>
        <li>
          We don’t permit businesses to access your personal information, unless
          you decide to make this information available
        </li>
        <li>
          We don’t forward or transfer your information to any third parties, other
          than the organisations detailed in this document, which are required to
          operate The Community. This disclosure is necessary in your use of The
          Community and these organisations are required to respect and maintain
          our commitments to your privacy and security
        </li>
      </ul>
      <p>We are absolutely committed to these policies.</p>
      <br/>
      <h4>What information do we collect?</h4>
      <p>
        We collect three broad types of information:
        <ul>
          <li>Information you directly provide us;</li>
          <li>Information about your use and interaction with The Community; and</li>
          <li>
            Information collected through the platform, applications, third parties
            or other websites
          </li>
        </ul>
      </p>
      <p>
        Information you directly provide us can include:
        <ul>
          <li>profile information;</li>
          <li>any responses you submit during activities;</li>
          <li>the content of any customer service correspondence;</li>
          <li>any other information you directly provide on The Community</li>
        </ul>
      </p>
      <p>
        Information about your use and interaction with The Community can include:
        <ul>
          <li>your IP address, geolocation and data stored in Pureprofile cookies;</li>
          <li>
            your activities on The Community, such as when you accept or skip an
            invitation, your participation in activities and your progression
            through a particular activity;
          </li>
          <li>
            your other account activity, such as when you login to your account,
            when you send us customer service correspondence and when you opt-in
            to an application;
          </li>
          <li>information about the device you’re using to access The Community; or</li>
          <li>
            your transaction history, including your account balance, the points
            you receive from participating in activities and any rewards you have
            requested
          </li>
        </ul>
      </p>

      <br/>
      <h4>How do we use the information we collect?</h4>
      <p>The Community’s use of information:</p>
      <p>
        We use the information you provide us to publish and deliver insights and
        relevant activities to you through The Community. This can include:
        <ul>
          <li>
            Using responses from an activity, which has been provided to a business
            in a de-identified way, for further analysis or use, including
            publication of the de-identified results;
          </li>
          <li>Help detect and prevent fraudulent activity;</li>
          <li>Maintain the data quality of activity responses;</li>
          <li>Improve and personalize The Community experience;</li>
          <li>Maintain, operate and improve The Community; and</li>
          <li>Promote our services on affiliated third party websites using banner ads or other materials</li>
        </ul>
      </p>

      <br/>
      <h4>Businesses’ use of information</h4>
      <p>
        There are times when businesses may require access to profile information
        such as:
        <ul>
          <li>Profile information to help determine the target audience for an activity; or</li>
          <li>The responses you submit during an activity</li>
        </ul>
      </p>
      <p>
        All information provided to businesses will be presented in an aggregated
        and de-identified format, unless you grant us express permission to do
        otherwise.
      </p>
      <p>
        Businesses are also allowed to ask you to provide your name, email address
        and other contact information during an activity. You should know that:
        <ul>
          <li>
            you are providing this information directly to the business and not The
            Community;
          </li>
          <li>
            your information will be dealt with in accordance with the relevant
            business’ Privacy Policy and not The Community’s Privacy Policy; and
          </li>
          <li>
            you are always free to decline providing contact details to a business
          </li>
        </ul>
      </p>

      <br/>
      <h4>Information you can use</h4>
      <p>
        Information collected through the platform, applications, third parties
        and other websites may be used by you to allow you to:
        <ul>
          <li>Create a relationship between your account and other online accounts you’ve registered for;</li>
          <li>help detect and prevent fraudulent activity;</li>
          <li>improve the accessibility of the site; and</li>
          <li>participate in retargeting activities</li>
        </ul>
      </p>
      <br/>
      <h4>Accessing and controlling your information</h4>
      <p>
        If you:
        <ul>
          <li>want to obtain access to any personal information that we hold about you;</li>
          <li>object to profiling, including profiling for marketing purposes;</li>
          <li>believe any of your personal information that we hold is inaccurate, incomplete or it is not necessary to hold it; or</li>
          <li>want to delete your personal information from our records, you can email us at <a href="mailto:support@pureprofile.com">support@pureprofile.com</a> with the subject heading “Attention: Privacy Officer”. Please include your full name and account email address in the email. We will use reasonable efforts to resolve your request within 30 days.
</li>
        </ul>
              </p>
      <p>
        We will provide you with our reasons if we refuse your request and, if you disagree, you are entitled to ask us to make a note of the request with your information.
      </p>
      <p>
        You may also request that your personal information be restricted from processing where one of the following applies:
        <ul>
          <li>we are verifying the accuracy of personal information, which you believe to be inaccurate;</li>
          <li>the processing is unlawful and you oppose the erasure of personal information;</li>
          <li>we no longer require the personal information but you require it for the establishment, exercise or defence of legal claims; and</li>
          <li>you have objected to processing and we are verifying whether there are legitimate grounds to refuse the objection.</li>
        </ul>
      </p>

<br/>
      <h4>Disclosure to third parties</h4>
      <p>
        We will not sell, trade, give or rent any information to a third party unless:
        <ul>
          <li>you have expressly consented;</li>
          <li>such disclosure is permitted by the Terms of Service or this Privacy Policy; or</li>
          <li>required to do so by law.</li>
        </ul>
      </p>
      <p>
        When we provide information to third parties, profile information and activity responses are only disclosed to businesses in an aggregate and de-identified format. We never provide any personally identifiable Information about you to a business, unless you expressly grant us permission to do so. This permission will (unless otherwise indicated) generally be required on a case-by-case basis.
      </p>
      <br/>
      <h4>Overseas staff handling your data</h4>
      <p>
        The response data for some activities may be stored and/or analysed and aggregated by staff residing in the United States, United Kingdom, Australia, Greece, The Philippines and India. Our staff, including contractors, are required to maintain the same privacy and data protection standards as required under the General Data Protection Regulation.
      </p>

      <br/>
      <h4>Overseas third parties</h4>
      <p>
        Some activities may originate from businesses based outside of your country of residence and accordingly, you agree to allow your activity response data to be provided to these businesses. When this happens, we may need to use third party hosting facilities located outside of your country of residence to ensure your information is constantly accessible and backed up in secure data storage facilities. We will ensure all third party hosting providers used are not permitted access to your information.
      </p>

      <br/>
      <h4>Data matching</h4>
      <p>
        We may share your information with third parties to determine if these parties already hold information about you. We do this for the following purposes:
        <ul>
          <li>to seek your consent to add the information held by third parties to your The Community account; and</li>
          <li>to determine if you qualify for an activity (for example for businesses who want to deploy a survey to individuals already on their database)</li>
        </ul>
      </p>
      <p>
        If the third party does not hold information about you, we ensure that the information we shared with them is destroyed.
      </p>

      <br/>
      <h4>Linking to other websites</h4>
      <p>
        If you associate your account with another website or online account through a Pureprofile product, we may save any data retrieved via this association into our system. In this case, the nature of the data being transferred will be outlined to you and require your express consent. Read the terms of any application carefully before accepting and using an application.
      </p>

      <br/>
      <h4>Information security</h4>
      <p>
        We are committed to providing a secure online environment to use The Community and have invested considerable resources to protect your information from loss, misuse, corruption and unauthorised access. More precisely, we have invested in:
        <ul>
          <li>A secure server software (SSL) technology to encrypt the transfer of all personally identifiable information</li>
          <li>Firewalls that protect against unauthorised access of all of the data we collect and store</li>
        </ul>
      </p>

      <p>Due to the nature of the internet, it’s possible that information stored on our servers and data transmitted online can sometimes be accessed by unauthorized third parties. While we make significant investments to ensure that this risk is minimized, we cannot guarantee the information you provided us will never be compromised. By accepting this Privacy Policy, you acknowledge and accept these risks.</p>

      <br/>
      <h4>How to contact us</h4>
      <p>
        If you have any enquiries or complaints about either our Privacy Policies or procedures, including requesting access to or correcting personal information that The Community holds, please email us at support@pureprofile.com with the subject heading “Attention: Privacy Officer”. Please include your full name and account email address in the email.
      </p>

      <br/>
      <h4>Policy changes</h4>
      <p>We may make changes to the Privacy Policy from time to time and you’ll be notified of any changes.</p>

    </div>
  </section>
);

export default PrivacyPage;
